import { LitElement, html } from "lit";
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { customElement, property } from "lit/decorators.js";
import { style } from "./targetgroups.styles";
import { TargetGroupsElement } from "../targetgroups-element/targetgroups-element";

@customElement('sbk-target-groups')
export class TargetGroups extends LitElement {

  @property({type: String, attribute: 'headline'})
  headline = ''

  @property({attribute: 'image-position'})
  imagePosition?: 'left' | 'right' = 'left'

  elements: TargetGroupsElement[] = [];
  selectedIndex = 0;

  static get styles() {
    return style;
  }

  render() {
    return html`
      <div class="targetgroups__banner">
        <div class="targetgroups__wrapper ${this.imagePosition === 'left' ? 'targetgroups__image--left' : 'targetgroups__image--right'}">
        <p class="targetgroups__headline">${this.headline}</p>
        <div class="targetgroups__image">
          <sbk-image facet=${this.elements[this.selectedIndex]?.imageFacet} variant=${this.imagePosition === 'left' ? 'default' : 'alternative'}>
            ${this.elements[this.selectedIndex]?.image}
          </sbk-image>
        </div>
          <div class="targetgroups__tab-menu">
            <sbk-tab-menu @tab-change=${this.handleTabClick}>${this.renderTabMenuItems()}</sbk-tab-menu>
          </div>
        <div class="targetgroups__body">
          ${unsafeHTML(this.elements[this.selectedIndex]?.content)}
        </div>
        <div class="targetgroups__button">
          <sbk-button variant="secondary" href=${this.elements[this.selectedIndex]?.link} icon="chevron-right" icon-position="right">
            ${this.elements[this.selectedIndex]?.buttonLabel}
          </sbk-button>
        </div>
      </div>
      <slot @slotchange=${this.handleSlotChange}></slot>
      </div>
    `;
  }

  handleSlotChange() {
    const slot = this.shadowRoot!.querySelector('slot');
    if (slot) {
      this.elements = Array.from(slot.assignedElements()) as TargetGroupsElement[];
    }
    this.requestUpdate();
  }

  renderTabMenuItems() {
    const items = [];
    for (const element of this.elements) {
      items.push(html`<sbk-tab-menu-item>${element.headline}</sbk-tab-menu-item>`);
    }
    return items;
  }

  handleTabClick(event: CustomEvent) {
    this.selectedIndex = event.detail.index;
    this.requestUpdate();
  }

}