import { css, unsafeCSS } from 'lit';
import { sbkCoreBreakpointLg, sbkCoreBreakpointXl } from '@/tokens/variables.js'
import { buttonOnDarkStyles } from '../button/button--onDark.styles'
import { tabItemOnDarkStyles } from "../tab-menu-item/tab-menu-item--onDark.styles";
import { facet1OnDarkStyles } from "../image/styles/facet-1--onDark.styles";
import { facet2OnDarkStyles } from "../image/styles/facet-2--onDark.styles";
import {tabMenuOnDarkStyles} from "@/components/tab-menu/tab-menu--onDark.styles.ts";

const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const style = css`
    :host {
        ${buttonOnDarkStyles}
        ${tabItemOnDarkStyles}
        ${tabMenuOnDarkStyles}
        ${facet1OnDarkStyles}
        ${facet2OnDarkStyles}
    }

    .targetgroups__banner {
        background: var(--sbkTargetgroupsColorBgColorEmphasised);
        padding: var(--sbkTargetgroupsSpacingSmallScrInsetTop) 24px var(--sbkTargetgroupsSpacingSmallScrInsetTop) 24px;
        overflow: hidden;
    }

    .targetgroups__wrapper {
        margin: 0 auto;
        max-width: var(--sbkCoreContentAreaMaxWidth);
        color: var(--sbkTargetgroupsColorFgColorOnDarkDefault);
        display: grid;
        grid-template-areas:
                "headline"
                "image"
                "tabs"
                "body"
                "button";
        gap: var(--sbkTargetgroupsSpacingSmallScrGap);
    }

    .targetgroups__headline {
        font: var(--sbkTargetgroupsTypographySmallScreenHeadline);
        grid-area: headline;
        margin: 0;
        width: 100%;
    }
        
        .targetgroups__image {
                width: var(--sbkTargetgroupsSizingViewportSmImageMaxWidth);
                aspect-ratio: 1;
                @media screen and (min-width: ${breakpointLg}) {
                        width: var(--sbkTargetgroupsSizingViewportLgImageMaxWidth);
                        height: auto;
                }
        }

        .targetgroups__tab-menu {
                grid-area: tabs;
                overflow-x: auto;
        }
   

    .targetgroups__image {
        grid-area: image;
        align-content: center;
        position: relative;
    }

    .targetgroups__body {
        font: var(--sbkTargetgroupsTypographySmallScreenBody);
        grid-area: body;
        padding-right: 8px;
        overflow-y: auto;
    }

    .targetgroups__button {
        grid-area: button;
    }

    @media screen and (min-width: ${breakpointLg}) {
        .targetgroups__wrapper {
            gap: var(--sbkTargetgroupsSpacingLargeScrGap);
            padding: var(--sbkTargetgroupsSpacingLargeScrInsetTop);
        }

        .targetgroups__headline {
            font: var(--sbkTargetgroupsTypographyLargeScreenHeadline);
        }

        .targetgroups__body {
            font: var(--sbkTargetgroupsTypographyLargeScreenBody);
        }
    }

    @media screen and (min-width: ${breakpointXl}) {
        .targetgroups__wrapper.targetgroups__image--left {
            grid-template-areas:
                  "image headline"
                  "image tabs"
                  "image body"
                  "image button";
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
            grid-template-rows: auto auto 1fr auto;

            sbk-image {
                translate: -5% 0;
            }
        }

        .targetgroups__wrapper.targetgroups__image--right {
            grid-template-areas:
                  "headline image"
                  "tabs image"
                  "body image"
                  "button image";
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
            grid-template-rows: auto auto 1fr auto;

            sbk-image {
                translate: -5% 0;
            }
        }
    }

`;